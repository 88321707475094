import { groq } from "next-sanity";

export const contentPageWithoutChildrenLanguageQuery = groq`*[_type in ["contentPage"] && language == $language && slug.current == $slug && parent->slug.current == $parentSlug && parent->parent->slug.current == $grandparentSlug][0]{
  "translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
    language,
    "slug": slug.current,
    "parentSlug": parent->slug.current,
    "grandparentSlug": parent->parent->slug.current
    },
  }`;

export const contentPageLanguageQuery = groq`*[_type in ["contentPage"] && language == $language && slug.current == $slug && parent->slug.current == $parentSlug][0]{
  "translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
    language,
    "slug": slug.current,
    "parentSlug":parent -> slug.current,
    },
  }`;

export const contentOverviewLanguageQuery = groq`*[_type in ["contentPage", "contentOverviewPage"] && !defined(parent) && language == $language && slug.current == $slug][0]{
    "translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
      language,
        "slug": slug.current,
      },
    }`;

export const LANGUAGE_QUERY_ARRAY = [
  contentOverviewLanguageQuery,
  contentOverviewLanguageQuery,
  contentOverviewLanguageQuery,
  contentPageLanguageQuery,
  contentPageWithoutChildrenLanguageQuery,
];

export const SPECIAL_PATHS_ARRAY = ["kort", "etaper"];
