import { createClient } from "next-sanity";

import {
  apiVersion,
  dataset,
  importToken,
  projectId,
  readOnlyToken,
  useCdn,
} from "./constans";

export const readOnlyClient = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn,
  token: readOnlyToken,
});

export const publicClient = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn,
});

export const importClient = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn,
  token: importToken,
});
