import Negotiator from "negotiator";

import { defaultLanguage, Language, languages } from "./languages";

export const negotiateLanguage = (language: string | null): Language => {
  const headers = { "accept-language": language ?? defaultLanguage };

  return (new Negotiator({ headers }).language(
    languages as unknown as string[]
  ) ?? defaultLanguage) as Language;
};
