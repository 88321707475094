import { composeURL, SlugData } from "@lib/utils";
import { readOnlyToken } from "@lib/utils/sanity/constans";
import { publicClient } from "@lib/utils/sanity/sanityClient";
import { languages } from "@repo/i18n";
import { usePathname } from "next/navigation";
import { useLayoutEffect, useState } from "react";

import { LANGUAGE_QUERY_ARRAY, SPECIAL_PATHS_ARRAY } from "./const";

export async function getTranslationLinks(
  query: string,
  {
    parentSlug,
    slug,
    language,
  }: { parentSlug?: string; slug: string; language: string }
) {
  const { translations } = await publicClient.fetch(
    query,
    {
      slug,
      parentSlug,
      language,
    },
    {
      token: readOnlyToken,
    }
  );
  return translations.map((t: any) => ({
    key: t.language,
    url: parentSlug
      ? `/${t.language}/${t.slug}/${t.subslug}`
      : `/${t.language}/${t.slug}/`,
  }));
}

export function useLanguageResolver() {
  const [data, setData] = useState<{ key: string; url: string }[] | null>(null);
  const pathName = usePathname();
  const parts = pathName.split("/").filter(part => part.length > 0);

  useLayoutEffect(() => {
    function changeLanguageUrl(url: string, newLanguage: string) {
      const parts = pathName.split("/").filter(part => part.length > 0);
      parts[0] = newLanguage;
      const newUrl = "/" + parts.join("/");
      return newUrl;
    }

    async function getTranslationLinks() {
      const query = LANGUAGE_QUERY_ARRAY[parts.length];
      const params = {
        grandparentSlug: parts[1],
        parentSlug: parts[3] ? parts[2] : parts[1],
        slug: parts[3] || parts[2] || parts[1],
        language: parts[0],
      };
      try {
        const { translations } = await publicClient.fetch<{
          translations: SlugData[];
        }>(query, params, {
          token: readOnlyToken,
        });
        translations.length === 0
          ? setData(
              languages.map(language => ({
                key: language,
                url: changeLanguageUrl(pathName, language),
              }))
            )
          : setData(
              translations.map(t => ({
                key: t.language || "da",
                url: `/${t.language || "da"}/${composeURL(t)}`,
              }))
            );
      } catch {
        setData(
          languages.map(language => ({
            key: language,
            url: changeLanguageUrl(pathName, language),
          }))
        );
      }
    }

    if (!parts[1] || SPECIAL_PATHS_ARRAY.indexOf(parts[1]) > -1) {
      setData(
        languages.map(language => ({
          key: language,
          url: changeLanguageUrl(pathName, language),
        }))
      );
    } else {
      getTranslationLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);
  return [parts[0], data] as const;
}
