"use client";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import { cn } from "@lib/utils";
import { usePathname, useRouter } from "next/navigation";

import { useLanguageResolver } from "./utils";

interface ILanguageSwitcher {
  className: string;
}

export function LanguageSwitcher({ className }: ILanguageSwitcher) {
  const [language, data] = useLanguageResolver();
  const router = useRouter();
  const path = usePathname();

  return (
    <Select onValueChange={value => router.push(value)} value={path}>
      <SelectTrigger
        className={cn(
          "w-[76px] rounded-xl bg-transparent text-white",
          className
        )}
      >
        <SelectValue
          className="font-bold text-white"
          placeholder={language.toUpperCase()}
        />
      </SelectTrigger>
      <SelectContent>
        {data?.map(i => (
          <SelectItem key={i.key} value={i.url}>
            {i.key.toUpperCase()}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
